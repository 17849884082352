/**
 * Testimonial Slider ACF Layout Styles
 * DBS>Interactive
 */

.testimonial-slider {
    
    &__cell {
        width: 100%; // Set number of visible slides by width percentage. This is one slide.
        
        &-text {
            &-author-name {
                font-weight: 600;
            }
            
            &-author-title {
                font-size: .9rem;
            }
        }
    }

    &__element {
        box-shadow: none !important;
        position: relative;
    }

    .flickity-viewport {
        transition: height 0.25s;
    }

    // Nav buttons
    .flickity-button {
        z-index: 10;
    }

    .flickity-page-dots {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        bottom: 0;

    }

    // white circle styles for the dots
    button.dot {
        border-radius: 100%;
        width: .75em;
        height: .75em;
        padding: 0;
        margin: 0em .75em;
        opacity: 1;
        background: transparent;
        transform: scale(1);
        border: .1em solid $white;

        &:focus,
        &:hover,
        &.is-selected {
            background-color: $black;
            border-color: $white;
            transform: scale(1.5);
            transition: transform .25s linear;
        }
    }

    @include media($screen-sm) {
        &__cell {
            margin-right: $spacing;
        }
    }
    
    
    &.bg-blue .testimonial-slider__cell-text {
        background-color: rgba(255, 255, 255, 0.05);
    }
    
    &.bg-dark .testimonial-slider__cell-text {                
        background-color: rgba(132, 151, 132, 0.4);
    }
    
    &.one-up,
    &.two-up {
        
        .flickity-viewport {
            overflow: hidden;
        }
        
        .testimonial-slider__cell {
            margin-right: $spacing;
            position: relative;
            text-align: center;
            
            &-text {                
                padding: $spacing #{$spacing * 2};
                position: relative;
                z-index: 0;
            }
        }
        
        .testimonial-slider__element {
            margin-top: #{$spacing * 2};
        }
        
        .testimonial-slider__heading {
            padding: 0 $spacing #{$spacing * 2};
        }
        
        .testimonial-slider__image {
            border-radius: 50%;
            height: 8rem;
            margin: 0 auto;
            overflow: hidden;
            width: 8rem;
            z-index: 1;
        }
    }
    
    @include media($screen-md-max, 'max') {            
        .flickity-button {
            background: transparent;
            
            &-icon {
                fill: $grayLighter;
            }
        }
        
        .flickity-prev-next-button {
            bottom: 0;
            height: 50px;
            width: 50px;
            top: unset;
        
            &.previous {
                left: 14%;
            }
            
            &.next {
                right: 14%;
            }
        }
    }
    
    @include media($screen-md) {
        &.two-up {
            .flickity-prev-next-button {
                top: 50%;
            }
                        
            .testimonial-slider__cell {
                margin-right: $spacing;
                width: calc(50% - #{$spacing / 2});
                
                &-text {
                    padding: $spacing;
                    display: flex;
                        flex-direction: column;
                        gap: $spacing;
                    
                    &-quote {
                        margin-bottom: 0;
                        order: 1;
                    }
                }
            }
            
            .testimonial-slider__element {
                padding: 0 5rem;
            }
            
            .testimonial-slider__image {
                height: 4rem;
                left: $spacing;
                top: $spacing;
                transform: none;
                width: 4rem;
            }
        }
    }
}