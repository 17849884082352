/**
 * Programs Slider ACF Layout Styles
 * DBS>Interactive
 */

.programs-slider {

    // white circle styles for the dots
    button.dot {
        border-radius: 100%;
        width: .75em;
        height: .75em;
        padding: 0;
        margin: 0em .75em;
        opacity: 1;
        background: transparent;
        transform: scale(1);
        border: .1em solid $white;

        &:focus,
        &:hover,
        &.is-selected {
            background-color: $black;
            border-color: $white;
            transform: scale(1.5);
            transition: transform .25s linear;
        }
    }

    .contain {
        display: flex;
        flex-direction: column;
        gap: #{$spacing * 3};
    }

    .flickity-viewport {
        transition: height 0.25s;
    }

    .flickity-button {
        @include size(2.5rem);
        transform: none;
        top: 100%;

        svg path {
            fill: $green;
        }

        &.next {right: 0;}

        &.previous {
            left: auto;
            right: 4rem;
        }
    }

    &__cell {
        // box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.1);
        height: 100%;
        // padding: #{$spacing * 2} $spacing;
        width: 100%; // Set number of visible slides by width percentage. This is one slide.
        
        a {
            font-family: $font-myriad-pro-condensed;
            text-decoration: none;
        }
        
        // img {
        //     margin-bottom: $spacing;
        // }
        img {display: block;}
        
        // &-upper {
        //     color: $black;
        //     margin-bottom: $spacing;
        // }
        
        &-upper {
            max-height: 100%;
            
            img {
                @include object-fit('cover', 'center');
            }
        }
        
        &:not(:last-child) {
            margin-bottom: #{$spacing};
        }
        
        // &.green {
        //     border-top: .75rem solid $greenLight;
        // }
        
        // &.teal {
        //     border-top: .75rem solid $teal;
        // }
        
        // &.orange {
        //     border-top: .75rem solid $orange;
        // }
    }

    &__element {
        box-shadow: none !important;
    }

    &__left-content {
        position: relative;
        width: 100%;
        z-index: 2;
        
        h2 {
            font-family: $font-myriad-pro;
            position: relative;
            
            &::before {
                background-image: url(/wp-content/themes/leadershiplou/library/icons/src/triangle-down.svg);
                background-repeat: no-repeat;
                background-size: 100%;
                content: '';
                height: 7rem;
                position: absolute;
                    top: -1.5rem;
                    left: -3.5rem;
                width: 7rem;
                z-index: -1;
            }
        }
    }

    @include media($screen-sm) {
        &__cell {
            display: flex;
                flex-direction: column;
                justify-content: space-between;
            margin: 0 #{$spacing / 2} !important;
            width: calc(45% - #{$spacing/2});
        }
        
        &__element {
            position: relative;
            
            .flickity-viewport {
                overflow: visible;
            }

            &::after {
                content: 'flickity';
                opacity: 0;
            }

            &::before {
                background: $white;
                content: '';
                height: 100%;
                position: absolute;
                    right: calc(100% + .75rem);
                    top: 0;
                width: 100vw;
                z-index: 1;
            }
        }
    }

    @include media($screen-md) {
        .contain {
            flex-direction: row;
        }

        &__left-content {
            display: flex;
                flex-direction: column;
                justify-content: center;
            width: 30%;
            
            h2::before {
                height: 9rem;
                    top: -2rem;
                    left: -4.5rem;
                width: 9rem;
            }
        }
        
        // &__cell {
        //     margin: #{$spacing/2};
        //     width: 33.33%;
        // }

        &__element {
            width: 70%;
        }
    }
}