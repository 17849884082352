.news-feed {

	&__heading {
		margin-bottom: $spacing;
	}

	&__list {
		&-item {
			display: flex;
				align-items: center;
			margin-bottom: 2rem;

			&:last-child {margin-bottom: 0;}

			h3.h6 {
				font-size: 1.125rem;
				margin-bottom: 1rem;
			}
		}
	}

	&__featured {

		&-wrapper {
			margin-bottom: $spacing;
		}

		&-image {
			background: $white;
			border-radius: 1rem 1rem 0 0;
			height: 0;
			overflow: hidden;
			padding-bottom: 60%;
			position: relative;

			img { @include object-fit(cover, center); }
		}

		&-text {
			background: $white;
			border-radius: 0 0 1rem 1rem;
			padding: $spacing;

			h3.h5 {font-size: 1.25rem;}
		}

		&-category {
			background: #C2D1D9;
			border-radius: .25rem;
			display: inline-block;
			margin-bottom: 1rem;
			padding: .375rem 1rem .3rem;
		}
	}

	@at-root .layout.news-feed .news-feed__link {
		background: $white;
		border-radius: 1rem;
		display: block;
		padding: $spacing;

		&:after {
			right: 1.5rem !important;
		}
	}

	@include media($screen-sm) {

		&__heading {
			margin-bottom: $spacing*2;
		}

		&__list {
			width: 50%;

			&-item {margin-bottom: 1rem;}

			&-text {
				width: 55%;

				h3.h6 {font-size: 1.125rem;}
			}

			&-image {
				background-color: white;
				height: 0;
				margin-right: $spacing;
				overflow: hidden;
				padding-bottom: 27%;
				position: relative;
				width: calc(45% - 1.5rem);
	
				img { @include object-fit(cover, center); }
	
				&::after {
					background: $teal;
					content: '';
					height: 95%;
					position: absolute;
						bottom: 0;
						right: 0;
					width: 4px;
				}
			}
		}

		&__featured {
			margin-bottom: $spacing;

			&-wrapper {
				margin-bottom: 0;
				padding-left: 3rem;
				width: calc(50% - 3rem);
			}

			&-text {
				h3.h5 {font-size: 1.5625rem;}
			}
		}
	}
}