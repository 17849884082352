.clustered-cta {

	&__left {
		margin-bottom: $spacing*2;
		padding-bottom: 3rem; // Space for lines graphic
		padding-left: 6rem; // Space for lines graphic
		position: relative;

		&-image {
			border-radius: 2rem;
			height: 0;
			overflow: hidden;
			padding-bottom: 120%;
			position: relative;

			img { @include object-fit(cover, center); }
		}
	}

	&__icon {
        margin-right: .5rem;
        width: 3rem !important;

		img {
			display: block;
			width: 100%;
		}
	}

	&__right {

		&-content {
			margin-bottom: $spacing*2;
			
			ul {
				list-style: none;
				padding: 0;

				li {
					font-weight: bold;
					margin-bottom: .5rem;
					padding-left: 2.75rem;
					position: relative;

					&::before {
						border-top: 4px solid $teal;
						content: '';
						position: absolute;
							top: .6rem;
							left: 0;
						width: 2rem;
					}
				}
			}
		}

		&-top {
			margin-bottom: 1rem;

			a {
				line-height: 1.3;
				text-decoration: none;

				&:hover,
				&:focus,
				&:active {text-decoration: underline;}
			}

			strong {
                flex: 1;
                line-height: 1;
            }
		}
	}

	&__cta {
		margin-bottom: $spacing;
		margin-right: $spacing;

		&:last-child {
			margin-bottom: 0;
			margin-right: 0;
		}
	}

	&__description { font-size: .875rem; }

	&__lines {
		display: flex;
		height: calc(100% + #{$vSpacing * 2});
		width: 6rem;
		position: absolute;
			left: 0;
			bottom: 0;

		&-column {
			margin-right: .5rem;

			div {
				border-radius: 1rem;
			}

			&.column-1 {
				width: $spacing;
				
				div {
					&:nth-child(1) {
						background: $teal;
						height: calc(65% - .5rem);
						margin-bottom: .5rem;
					}

					&:nth-child(2) {
						background: $igniteRed;
						height: 25%;
					}
				}
			}

			&.column-2 {
				width: $spacing*2;

				div {
					background: $greenLight;
					border-radius: 0 0 2rem 2rem;
					height: 100%;
				}
			}

			&.column-3 {
				width: $spacing;

				div {

					&:nth-child(1) {
						background: $igniteRed;
						border-radius: 0 0 1rem 1rem;
						height: calc(50% - .5rem);
						margin-bottom: .5rem;
					}

					&:nth-child(2) {
						background: $yellow;
						height: 50%;
					}
				}
			}
		}
	}

	@include media($screen-sm) {

		&__left {
			margin-bottom: 0;
			padding-left: #{$spacing * 4}; // Space for lines graphic
            padding-right: #{$spacing * 2};
			width: 50%;
		}

		&__right {
			align-self: center;
			padding-left: #{$spacing * 2};
			width: 50%;
		}

		&__cta {
			flex: 1;
		}

		&__lines {
			height: calc(100% + #{$vSpacing * 3});
			width: 9rem;
		}

		&.reverse {
			.contain {flex-direction: row-reverse;}

			.clustered-cta__right {
				padding: 0 4.5rem 0 0;
			}

			.clustered-cta__left {
				padding-left: 0;
				padding-right: 10rem;

				.clustered-cta__lines {
					left: auto;
					right: 0;
				}
			}
		}
	}

	// @include media($screen-md) {
    //     &__left {
    //         padding-left: #{$spacing * 6};
    //         padding-right: #{$spacing * 3};
    //     }

	// 	&__right {
	// 		padding-left: #{$spacing * 3};
	// 	}
	// }
}