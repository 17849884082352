/**
 * DBS Chevron Styles
 * DBS>Interactive
 */
#dbs-chev {
	position: relative;

	svg {
		// Necessary for ie height fix
		height: 2rem;
		max-width: 1rem;
	}

	strong {
		@include font-scale(-1, $bodyFontSize);
		float:right;
		font-weight: normal;
		text-align: center;
		width: 15em;

		@include media($screen-sm) {width: 21em;}
	}

	.text {
		background: $black;
		max-width: 0;
		overflow: hidden;
		padding: .25em 0;
		position: absolute;
			right: 2em;
			top: 1px;
		transition: all .5s ease-out;
	}

	&.hover span, &:hover span, &:focus span { max-width: 23em; }
}
