/**
 * Quick Links
 */

.quick-links {
    select {
        appearance: none;
        background-color: $greenLight;
        border-radius: 0.25rem;
        color: $white;
        font-family: $font-myriad-pro;
        font-size: .9rem;
        font-weight: 600;
        padding: #{$spacing / 2} #{$spacing * 2} #{$spacing / 2} #{$spacing / 2};
    }
    
    &__select-wrapper {
        margin: 0 auto;
        position: relative;
        width: fit-content;
    }
    
    &__select-wrapper::after {
        background-image: url('#{$libraryPath}/icons/src/arrow-down-white.svg');
        bottom: .5rem;
        content: '';
        height: 2rem;
        position: absolute;
        right: .5rem;
        width: 2rem;
    }
    
    
    &__container {
        text-align: center;
    }
    
    &__heading {
        color: $white;
        font-family: $font-myriad-pro;
        text-transform: none;
    }
    
    @include media($screen-xs) {
        select {
            font-size: 1.25rem;
            padding: #{$spacing / 2} #{$spacing * 2} #{$spacing / 2} $spacing;
        }
    }
}
