/**
 * Two Columns Flex Layout
 * DBS Interactive
 */

 .two-columns {

	&__left,
	&__right {
		position: relative;

		img {display: block;}
	}

    &.gradient-with-border {
        background: linear-gradient(#637962, #333532);
        border-top: 13px solid $teal;
        color: $white;
        padding: #{$spacing * 2} 0;
        
        h1, h2, h3, h4, h5, h6 {
            color: $white;
        }
        
        h3 {
            font-family: $font-myriad-pro;
        }
                
        .contain {
            align-items: center;
        }
                
        @include media($screen-sm-max, 'max') {
            .two-columns__left {
                margin-bottom: #{$spacing * 2};
            }

            .two-columns__right {
                text-align: center;
            }
        }
        
        @include media($screen-sm) {
            padding: #{$spacing * 4} 0;
            
            .two-columns__left {
                width: calc(55% - #{$spacing});
            }
            
            .two-columns__right {
                width: calc(45% - #{$spacing});
            }
        }
    }
    
    &.two-thirds-right {
    	@include media($screen-md) {
    		.two-columns__right { width: 60%; }
    		.two-columns__left {
    			padding-right: $spacing;
    			width: 40%;
    		}
    	}
    }
    
    &.two-thirds-left {
    	@include media($screen-md) {
    		.two-columns__right {
    			padding-left: $spacing;
    			width: 40%;
    		}
    		.two-columns__left {width: 60%;}
    	}
    }

	@include media($screen-sm-max, 'max') {
		&__left {margin-bottom: $spacing;}

		&.full-bleed {
			.has-img { 
				margin-left: -#{$spacing};
				margin-right: -#{$spacing};
			}
		}

		& + .full-bleed {
			margin-top: $spacing !important;
		}

		.two-columns__left.has-img {
			margin-bottom: 0;
			margin-top: $spacing;
			order:2;
		}
	}

	@include media($screen-sm) {
		&__left,
		&__right {
			width: calc(50% - #{$spacing*2});
		
			&.contain-img { img {@include object-fit(contain, center);} }
			&.cover { img {@include object-fit(cover, center);} }

			&.contain-img,
			&.cover {
				align-self: stretch;
			}
		}

		&.full-bleed {
			position: relative;

			.two-columns__left:not(.has-img),
			.two-columns__right:not(.has-img) {
				padding: 3rem 0;
			}

			.two-columns__left:not(.has-img) {margin-right: auto;}
			.two-columns__right:not(.has-img) {margin-left: auto;}

			.has-img {
				height: 100%;
				position: absolute;
					top: 0;
				width: 50%;

				&.two-columns__left {left: 0;}
				&.two-columns__right {right: 0;}
				
				img { @include object-fit(cover, center); }
			}
		}	
	}

	@include media($screen-md) {

		&.full-bleed {
			.two-columns__left:not(.has-img),
			.two-columns__right:not(.has-img) {
				padding: 4.5rem 0;
			}
		}
	}
}