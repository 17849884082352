/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */

.site-footer {
	overflow: hidden;
	clear: both;
	margin-top: auto; // Sticky footer

	&__container {
		padding: #{$spacing*2} $spacing;
	}

	// Resets
	ul { list-style: none; margin: 0; padding: 0; }

	// Default link styles
	a {
		text-decoration: none;
		
		&:hover { text-decoration: underline; }
	}

	nav {font-family: $font-myriad-pro;}

	.site-footer__logo {
		a {display: block;}

		svg {
			display: block;
			max-width: 10em;
			padding-bottom: 1.5em;
		}
	}

	&__business {
		ul, li {
			display: block;
		}
	}

	&__navigation {

		.footer-menu__item {
			a {
				display: block;
				font-weight: 400;
				padding: .25rem 0;
			}

			&.toplevel {
				margin-bottom: 1rem;

				& > a {color: $white; font-weight: 700; letter-spacing: 1px;}
			}
		}

		li { display: block; }
	}

	.social-media {
		margin: 1.5em 0 0;

		&__link {
			display: inline-block;
			margin-right: .75rem;
		}

		a {
			display: block;
			width: 1.35em;
		}

		svg path {fill: $white;}
	}

	&__credits {

		.contain {
			padding: $spacing;
		}

		.legal-footer {
			display: inline-block;

			ul, li {display: inline-block;}

			a {font-weight: 400;}

			.legal-menu__item:not(:last-child) {
				border-right: 1px solid $white;
				margin-right: .5rem;
				padding-right: .75rem;
			}
		}
	}

	@include media($screen-md-max, 'max') {

		&__logo svg {
			margin: 0 auto;
			padding-bottom: 3rem;
		}

		&__content {margin-bottom: 3rem;}

		&__navigation {
			margin: 0 -$spacing;

			.footer-menu__submenu {display: none;}

			.footer-menu__item {

				&--parent {
					position: relative;

					&.open {
						.footer-menu__submenu {display: block;}
					}
				}

				&.toplevel {
					border-bottom: 1px solid $white;
					margin-bottom: 0;

					a {
						padding: 1rem 1.5rem;
					}
				}

				&:first-child.toplevel {
					border-top: 1px solid $white;
				}
			}

			.submenu-toggle {

				svg path {fill: $white;}
			}
		}

		&__credits {
			.contain {
				div {
					margin-top: $spacing;
					text-align: center;
				}
			}
		}
	}

	@include media($screen-md) {
		&__content {
			padding-right: 4.5rem;

			.site-footer__business li:first-child {margin-bottom: 1.5rem;}

		}

		&__navigation {
			flex: 1;

			> ul {
				display: flex;
					justify-content: space-between;
			}

			.footer-menu__item {
				a {
					&:after {
						background: $white;
						content: '';
						display: block;
						height: 1px;
						position: relative;
							top: 3px;
						transform: scaleX(0);
						transform-origin: 100% 0;
						transition: transform .25s ease-in-out;
					}
		
					&:hover,
					&:focus {
						text-decoration: none;
	
						&:after {
							transform: scaleX(1);
							transform-origin: 0 100%;
						}
					}
				}
			}

			.footer-menu__item.toplevel {
				padding-right: $spacing;

				> a {text-transform: uppercase;}
			}
		}

		&__credits {
			border-top: 1px solid $white;

			.contain {
				display: flex;
					align-items: flex-end;
					justify-content: space-between;
			}
		}
	}

	@include media($screen-lg) {
		&__content {
			padding-right: 6rem;
		}
	}
}
