.cards {

	&__card {
		background: $white;
		border-top: 8px solid $teal;
		margin-bottom: $spacing;
		padding: $spacing $spacing #{$spacing*2};
		position: relative;
		opacity: 0;
		transition: opacity $animationDuration;

		&.in-view {
			opacity: 1;
		}

		&:hover {
			.cards__link { transform: translate3d(.25rem, 0, 0); }
		}
	}

	&__icon {
		height: 2.25rem;
		margin-bottom: $spacing;
		position: relative;
		width: 2.5rem;

		img { @include object-fit(contain, center); }
	}

	&__link {
		@include size(2rem);
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		position: absolute;
			bottom: 1rem;
			right: 1rem;
		transition: transform .25s;
	}

	&__top,
	&__bottom {
		margin-left: auto;
		margin-right: auto;
		max-width: 40rem;
		opacity: 0;
		transition: opacity $animationDuration, transform $animationDuration;

		&.in-view {
			opacity: 1;
		}
	}

	&__top {
		margin-bottom: 3rem;
	}

	&__bottom {
		margin-top: 3rem;
	}

	&.plain {

		.cards__card {
			border: none;

			.cards__link {background-image: url('#{$libraryPath}/icons/src/arrow-right-purple-filled.svg') !important;}
		}
	}
	

	@include media($screen-sm) {

		&__card {
			margin: 0 $spacing #{$spacing*2};
		}

		&__wrapper {
			margin: 0 -#{$spacing} -#{$spacing*2};
		}

		&.two-up {

			.cards__card {width: calc( 50% - #{$spacing*2});}

			.cards__wrapper {
				max-width: 60rem;
			}
		}
	
		&.three-up {
	
			.cards__card {
				width: calc( 33.33% - #{$spacing*2});

				&:nth-child(2),
				&:nth-child(5),
				&:nth-child(8),
				&:nth-child(11) {transition-delay: .25s;}

				&:nth-child(3),
				&:nth-child(6),
				&:nth-child(9),
				&:nth-child(12) {transition-delay: .5s;}
			}
		}

		&.four-up {
			.cards__wrapper {margin: 0 -#{$spacing} -#{$spacing};}

			.cards__card {
				margin: 0 #{$spacing/2} $spacing;
				padding-bottom: $spacing;
				width: calc( 25% - #{$spacing});

				&:nth-child(2),
				&:nth-child(6),
				&:nth-child(10),
				&:nth-child(14) {transition-delay: .25s;}

				&:nth-child(3),
				&:nth-child(7),
				&:nth-child(11),
				&:nth-child(15) {transition-delay: .5s;}

				&:nth-child(4),
				&:nth-child(8),
				&:nth-child(12),
				&:nth-child(16) {transition-delay: .75s;}
			}
		}

		&.five-up {
			.cards__wrapper {margin: 0 -#{$spacing} -#{$spacing};}

			.cards__card {
				margin: 0 #{$spacing/2} $spacing;
				padding-bottom: $spacing;
				width: calc( 20% - #{$spacing});

				&:nth-child(2),
				&:nth-child(7),
				&:nth-child(12),
				&:nth-child(17) {transition-delay: .25s;}

				&:nth-child(3),
				&:nth-child(8),
				&:nth-child(13),
				&:nth-child(18) {transition-delay: .5s;}

				&:nth-child(4),
				&:nth-child(9),
				&:nth-child(14),
				&:nth-child(19) {transition-delay: .75s;}

				&:nth-child(5),
				&:nth-child(10),
				&:nth-child(15),
				&:nth-child(20) {transition-delay: 1s;}
			}
		}
	}
}