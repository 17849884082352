/**
 * Flex Blocks Layout
 * DBS>Interactive
 */
 
.flex-blocks {
    .cell {
        padding: 0;
        width: 100%;
    }
    
    @include media ($screen-sm) {
        .cell {
            width: calc(100% / 2 - #{$spacing / 2});
        }
    }
    
    @include media ($screen-md) {
        .cell {
            width: calc(100% / 3 - #{$spacing * 2 / 3});
        }
    }
}
